var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":'MediaPackageCodeInfo',"size":"xl","headerText":_vm.$t('MediaPackageCodes.data'),"headerIcon":_vm.mediaPackageCode.icon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.mediaPackageCode.mediaPackageKey,"title":_vm.$t('general.key'),"imgName":'number.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.mediaPackageCode.mediaPackageCode,"title":_vm.$t('general.code'),"imgName":'code.svg'}}),(
        _vm.mediaPackageCode.mediaPackageCodeUsedTypeToken == _vm.CODE_UDE_TYPE.Used
      )?[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("MediaPackageCodes.codeUseData"))+" ")]),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.formateDateTimeLang(
              _vm.mediaPackageCode.mediaPackageCodeUsed.codeUsedInData,
              _vm.mediaPackageCode.mediaPackageCodeUsed.codeUsedInTime
            ),"title":_vm.$t('actionsData.dateTime'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.mediaPackageCode.mediaPackageCodeUsed.userStudentInfoData
              ? _vm.mediaPackageCode.mediaPackageCodeUsed.userStudentInfoData
                  .userNameCurrent
              : '',"title":_vm.$t('userName'),"imgName":'user.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.mediaPackageCode.mediaPackageCodeUsed
              .joinInEducationalCategoryInfoData
              ? _vm.mediaPackageCode.mediaPackageCodeUsed
                  .joinInEducationalCategoryInfoData
                  .educationalCategoryNameCurrent
              : '',"title":_vm.$t('EducationalCategories.name'),"imgName":'EducationalCategories.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.mediaPackageCode.mediaPackageCodeUsed
              .joinInEeducationalGroupInfoData
              ? _vm.mediaPackageCode.mediaPackageCodeUsed
                  .joinInEeducationalGroupInfoData.educationalGroupNameCurrent
              : '',"title":_vm.$t('EducationalGroups.name'),"imgName":'EducationalGroups.svg'}})],1)]:_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.mediaPackageCode.mediaPackageCodeUsedTypeNameCurrent,"title":_vm.$t('MediaPackageCodes.codeUseStatus'),"imgName":'type.svg'}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }