import GeneralModel from "./../../general/GeneralModel";
import UserSalesFollowUpSetting from "./UserSalesFollowUpSetting";
import FollowUpSetting from "./FollowUpSetting";
import FollowUpSettingsFilter from "./FollowUpSettingsFilter";

export default class FollowUpSettings extends GeneralModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setGeneralModelInitialValue();
    this.userSalesFollowUpSettingsData = [];
    this.followUpSetting = new FollowUpSetting();
    this.userSalesFollowUpSetting = new UserSalesFollowUpSetting();
    this.filterData = new FollowUpSettingsFilter();
  }
  fillData(data) {
    if (data) {
      this.fillGeneralModelData(data);
      this.userSalesFollowUpSettingsData = data.usersData ?? [];
      let filter = { ...this.filterData, ...data.pagination };
      this.filterData.fillData(filter);
    } else {
      this.setInitialValue();
    }
  }
}
